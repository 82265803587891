.body {
  font-family: "Calibri", Helvetica, Arial, sans-serif;
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  //padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  //bottom: 12px;
  //left: -50px;
  min-width: 280px;
  left: -410px;
  width: 670px;
  //height: 360px;
  top: -200px;
  float: right;
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
  color: #fff;
}

.ol-popup-closer:after {
  content: "✖";
}

.fillScreen {
  z-index: 99;
}

.popup-content {
  padding-left: 30px;
  padding-right: 30px;
}

.modalTitle {
  background-color: #00a1df;
  color: #fff;
  padding: 10px;
  padding-left: 30px;
  border-top: 0px;
  border-bottom: 0px;
  width: auto;
  margin-block-start: 0em;
  font-weight: 700;
  font-family: "Calibri", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.modalSubTitle {
  background-color: #00a1df;
  color: #fff;
  padding-left: 30px;
  border-top: 0px;
  border-bottom: 0px;
  width: auto;
  margin-block-start: 0em;
  font-weight: 300;
  font-family: "Calibri", Helvetica, Arial, sans-serif;
  font-size: 13px;
}

//.ol-popup button,
//.ol-popup input[type="button"] {
//  background-color: #00a1df;
//  color: #fff;
//  border-color: #007cac;
//  font-weight: normal;
//  text-align: center;
//  cursor: pointer;
//  border: 1px solid;
//  white-space: nowrap;
//  margin-top: 10px;
//  padding: 4px 12px;
//  line-height: 1.5;
//  border-radius: 3px;
//  float: right;
//}

.tablestyle {
  width: 100%;
  margin-top: 20px;

  th {
    width: 25%;
  }

  td {
    padding-bottom: 15px;
  }
}

/* FIXME grexmanager causes unchecked checkboxes to be invisible with this styling

.styledCheckbox {
    appearance: none;
    background-color: #eee;
    padding: 6px;
    display: inline-block;
    position: relative;
}

.styledCheckbox:checked{
}
.styledCheckbox:checked:after{
    content: '\2714';
    font-size: 12px;
    position: absolute;
    top: -1px;
    left: 1px;
    color: #00a1df9e;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type='range'] {
      overflow: hidden;
      width: 80px;
      -webkit-appearance: none;
      background-color: #eeeeee;
    }
    input[type='range']::-webkit-slider-runnable-track {
      height: 10px;
      -webkit-appearance: none;
      color: #77bfe6;
      margin-top: -1px;
    }
    input[type='range']::-webkit-slider-thumb {
      width: 10px;
      -webkit-appearance: media-volume-sliderthumb;
      height: 10px;
      cursor: ew-resize;
      background: #00a1df;
      box-shadow: -80px 0 0 80px #77bfe6;
    }
}

input[type="range"]::-moz-range-progress {
  background-color: #77bfe6;
}
input[type="range"]::-moz-range-track {
  background-color: #eeeeee;
}
/**/
