.show-layers-content {

  margin-left: -30px;
  margin-right: -30px;

  .smallth {
    font-size: small;
    //padding: 15px;
    //font-weight: lighter;
    //background: none;
    //color: black;
    color: rgb(74, 74, 74);
    background: #eee;
    //padding: .75em 15px;
    padding-top: 8px;

    &:not(:first-child) {
      text-align: center;
    }
  }

  .smalltd {
    font-size: small;
    line-height: 1.0;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
  }

  .smalltdwords {
    font-size: small;
    line-height: 1.0;
    text-align: left;
    padding-left: 15px;
    font-weight: 300;
  }

  #popup-content {
    max-height: 285px;
    overflow: auto;
  }

}