.notitie {
    font-size: small;
    color: lightgrey;
    margin-block-start:0;
    font-weight:300;
}

.padding {
    padding-left: 40px;
    padding-bottom: 5px;
}


