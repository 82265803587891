.inputbarstyling {
    width:50%;
    box-sizing: border-box;
}

.labelstyles {
    //padding-left: 30px;
}

.padding {
    font-weight: 300;
}
