.mapviewer__tutorial {
  position: relative;
  background-color: white;
  font-size: 12px;
}

.whiteBackground {
  background-color: #fff;
}

.iconstyled {
  padding-right: 8px;
}

.tutorialSeeAlso {
  font-size: 82.5%;

  .tutorialSeeAlsoText {
    padding-bottom: .25em;
  }

  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;

    li {
      display: inline-block;
      padding-right: 1em;
      padding-bottom: .25em;
    }
  }
}
