.show-search-content {
  position: relative;
  padding: 16px 0;

  .search-input {
    width: 100%;
    box-sizing: border-box;
  }

  .address {
    background-color: #ddd;
    border: none;
    color: black;
    //padding: 16px 32px;
    //text-align: center;
    //font-size: 16px;
    //margin: 4px 2px;
    transition: 0.3s;
  }

  .address:hover {
    background-color: #3e8e41;
    color: white;
  }
}
