.infobox {

  .functionTitle {
    background-color: #00a1df;
    color: #fff;
    padding: 10px;
    padding-left: 40px;
    border-top: 0px;
    border-bottom: 0px;
    width: auto;
    margin-block-start: 0em;
    font-weight: 500;
    font-family: "Calibri", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 5px;
  }

  .buttons {
    margin-top: 2.5em;
  }

  .btn {
    margin-right: .5em;
  }

}