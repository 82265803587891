@use "mapviewer/adminpanel";

.mapviewer__openlayers {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  .ol-scale-bar {
    left: auto;
    right: 8px;
  }
}

.mapviewer__spinner {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 300;
}

.mapviewer__buttons {
  position: absolute;
  top: 50px;
  right: 10px;
  text-align: left;
  z-index: 200000;
  opacity: 0.9;
  box-sizing: content-box;

  * {
    box-sizing: content-box;
  }
}

.mapviewer__leftsidepane-buttons {
  border: 2px solid #eee;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 2px;
  width: fit-content;
}

.mapviewer__buttons,
.mapviewer__leftsidepane-buttons {
  .group {
    position: relative;
    display: block;
    background: white;
    padding: 2px;
    width: 64px;
  }

  .button {
    position: relative;
    display: inline-flex;
    width: 32px;
    height: 32px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background: white;
    color: #002967;
    text-decoration: none;

    &:hover {
      background: greenyellow;

      .icon-super-script {
        background: greenyellow;
      }
    }

    .icon {
    }

    .icon-super-script {
      position: absolute;
      display: flex;
      top: 2px;
      right: 2px;
      width: 12px;
      height: 12px;
      overflow: visible;
      justify-content: center;
      align-items: center;
      background: white;
      border-radius: 10000px;

      * {
        width: 80%;
        height: 80%;
      }
    }

    &.active {
      box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.75) inset;

      .icon {
        position: relative;
        top: 1px;
        left: 1px;
      }

      .icon-super-script {
        top: 3px;
        right: 1px;
      }
    }

    &.disabled {
      .icon {
        color: #ccc;
      }

      .icon-super-script {
        color: #ccc;
      }
    }
  }
}

.mapviewer__leftsidepane {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  overflow: visible;
  z-index: 2000000;
  box-sizing: content-box;

  //* {
  //  box-sizing: content-box;
  //}

  .sidepane {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 330px;
    background: white;
    overflow-y: auto;
    border-right-style: solid;
    border-color: #cccccc;
    border-right-width: 7px;

    &.hidden {
      display: none;
      width: 0;
    }

    .functionTitle {
      padding-left: 15px + 8px;
    }

    .infobox {
      ul {
        margin-left: 0;
        padding-left: 0;
        list-style-type: none;

        li input {
          box-sizing: border-box;
          width: 100%;
        }
      }
    }

    .table > tbody > tr > td:first-of-type {
      padding-left: 8px;
    }
  }
  .propertyList {
    overflow: auto;
    padding: 15px;
  }
}

.spinner {
  position: relative;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
}

.body__mapviewer__toasts {
  position: absolute;
  top: 0;
  right: 0;
  overflow: visible;
}

.toast {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 80px;
  height: 40px;
  margin: 2px 5px 2px 5px;
  padding: 2px 5px 2px 5px;
}

.toast-text {
  margin: 1px 1px 3px 3px;
  padding: 1px 1px 3px 3px;
}
.toast-x {
  right: 10px;
  margin: 1px 1px 3px 3px;
  padding: 1px 1px 3px 3px;
  color: #000000;
}

.toast-ok {
  background-color: #7fffd4;
  color: #006400;
}

.toast-info {
  background-color: #f0f8ff;
  color: #00ffff;
}

.toast-warning {
  background-color: #e9967a;
  color: #8b0000;
}

.toast-error {
  background-color: #ffb6c1;
  color: #800000;
}

iframe.mapviewer-oauth-iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: auto;
}

